import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import RequireAuth from "./components/require-auth";
import { AppProvider } from "./contexts/app-context";
import PersistLogin from "./components/persist-login";

import Layout from "./components/layout";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import ForgotPasswordPage from "./pages/forgot-password";
import DashboardPage from "./pages/dashboard";
import LogImpactPage from "./pages/log-impact";
import ResourcesPage from "./pages/resources";
import NotFoundPage from "./pages/not-found";

function App() {
  const navigate = useNavigate();

  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          {/* <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} /> */}

          <Route element={<PersistLogin />}>
            {/* public routes that should persist auth if exists */}
            {/* <Route path="log-impact" element={<LogImpactPage />} />
            <Route path="resources" element={<ResourcesPage />} /> */}

            {/* protected routes that require auth */}
            {/* <Route element={<RequireAuth />}>
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route path="dashboard" element={<DashboardPage />} />
            </Route> */}
          </Route>

          <Route path="log-impact" element={<LogImpactPage />} />
          <Route path="not-found" element={<NotFoundPage />} />

          {/* catch all */}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </AppProvider>
  );
}

export default App;
