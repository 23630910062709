import React, { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useAppContext from "../../contexts/app-context";
import { apiClient } from "../../services/api-client";

import "./styles.scss";

export default function SiteSelect({ updateSelectedSite }: any) {
  const siteDefault = {
    id: 0,
    name: "",
    stateCode: "",
    stateName: "",
  };

  const { offlineMode } = useAppContext();

  const [formData, setFormData] = useState<SiteModel>({ ...siteDefault });
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [siteSearch, setSiteSearch] = useState<any>({ state: "", term: "" });
  const [siteSearchTermError, setSiteSearchTermError] = useState("");
  const [siteSearchClicked, setSiteSearchClicked] = useState(false);
  const [siteSearchResults, setSiteSearchResults] = useState([]);
  const [createNewSite, setCreateNewSite] = useState(false);
  const [validationResult, setValidationResult] = useState<SiteModel>({
    ...siteDefault,
  });

  const [states, setStates] = useState<Array<StateModel>>([]);

  useEffect(() => {
    const getStates = async () => {
      if (offlineMode) {
        setStates(require("../../data/states.json"));
      } else {
        await apiClient
          .get(`/states`)
          .then((response: any) => {
            const newResults = response.data
              .sort((a: any, b: any) => a.name > b.name)
              .map((item: any) => ({
                code: item.code,
                name: item.name,
              }));

            setStates(newResults);
          })
          .catch((err: any) => console.error(err));
      }
    };

    getStates();
  }, []);

  useEffect(() => {
    validateForm(formData);
  }, [formData]);

  const handleSiteSearchChange = (fieldName: string, newValue: string) => {
    setErrorMessage("");
    setSiteSearchTermError("");

    setSiteSearch((prevdata: any) => {
      return { ...prevdata, [fieldName]: newValue };
    });
  };

  const handleSearchSitesClick = () => {
    if (!siteSearch.term) {
      setSiteSearchTermError("A search term is required.");
      return;
    }

    const sendRequest = async (searchterm: string, state: string) => {
      await apiClient
        .get(`/sites?searchterm=${searchterm}&state=${state}`)
        .then((response: any) => {
          const newResults = response.data
            .sort((a: any, b: any) => a.site_name > b.site_name)
            .map((item: any) => ({
              id: item.site_id,
              name: item.site_name,
              state: item.site_state,
              displayName: `${item.site_name} (${item.state_name})`,
            }));

          setSiteSearchResults(newResults);
        })
        .catch((err: any) => console.error(err));
    };

    sendRequest(siteSearch.term, siteSearch.state);

    setSiteSearchClicked(true);
  };

  const handleNewSiteChange = (fieldName: string, newValue: string) => {
    setErrorMessage("");

    setFormData((prevdata) => {
      return { ...prevdata, [fieldName]: newValue };
    });
  };

  const validateForm = (dataToValidate: SiteModel) => {
    const newValidationResult = {
      id: 0,
      name: !dataToValidate.name ? "Mandatory field." : "",
      stateCode: !dataToValidate.stateCode ? "Mandatory field." : "",
      stateName: "",
    };

    setValidationResult(newValidationResult);

    const inputIsValid = !(
      newValidationResult.name || newValidationResult.stateCode
    );

    setFormIsValid(inputIsValid);

    return inputIsValid;
  };

  const handleSearchResultClick = (site: SiteModel) => {
    // setFormData((prevdata) => {
    //   return { ...prevdata, siteId: site.id, siteName: site.name };
    // });
    updateSelectedSite(site.id, site.name);
  };

  const resetForm = () => {
    setSiteSearch({ state: "", term: "" });
    setSiteSearchClicked(false);
    setSiteSearchResults([]);

    setCreateNewSite(false);
    setFormData({ ...siteDefault });
    setFormIsValid(false);
    setErrorMessage("");
  };

  const handleCancelClick = () => {
    resetForm();
  };

  const handleCreateNewSiteClick = () => {
    if (validateForm(formData)) {
      const sendRequest = async () => {
        await apiClient
          .post(`/sites`, formData)
          .then((response: any) => {
            updateSelectedSite(response.data, formData.name);
          })
          .catch((err: any) => console.error(err));
      };

      sendRequest();
      resetForm();
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="site-selector-modal"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Choose a site</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setCreateNewSite(false)}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                To search for a site, enter a search term in the{" "}
                <span className="fw-bold">'Search term'</span> text field and
                click the <span className="fw-bold">'Search'</span> button.
                Select your desired site from the list of results.
              </p>
              <p>
                If the site you're looking for doesn't exist in the search
                results, you can choose to create a new one.
              </p>
              {!createNewSite && (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label fw-medium">State</label>
                        <select
                          className="form-select"
                          name="state"
                          value={siteSearch.state}
                          onChange={(event) =>
                            handleSiteSearchChange(
                              event.target.name,
                              event.target.value
                            )
                          }
                        >
                          <option value=""></option>
                          {states.map((state, index) => (
                            <option key={index} value={state.code}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label fw-medium">
                          Search term{" "}
                          <span className="required-asterisk">*</span>
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className={`form-control ${
                              siteSearch.term &&
                              (siteSearchTermError ? "is-invalid" : "is-valid")
                            }`}
                            name="term"
                            value={siteSearch.term}
                            onChange={(e) =>
                              handleSiteSearchChange(
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => handleSearchSitesClick()}
                          >
                            Search
                          </button>
                        </div>
                        <div className="invalid-feedback">
                          {siteSearchTermError}
                        </div>
                      </div>
                    </div>
                  </div>

                  {siteSearchClicked && (
                    <div className="mb-3 border border-1 rounded">
                      <div className="p-2">
                        <label className="form-label fw-medium">Results</label>
                        {siteSearchResults?.length > 0 ? (
                          siteSearchResults.map((site, siteIndex) => (
                            <div key={`${siteIndex}`} className="p-1">
                              <a
                                className="site-result-link link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                                data-bs-dismiss="modal"
                                onClick={() => handleSearchResultClick(site)}
                              >
                                {site.displayName}
                              </a>
                            </div>
                          ))
                        ) : (
                          <p>No matching records found.</p>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              {siteSearchClicked && (
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="createNewSite"
                    checked={createNewSite}
                    onChange={() => setCreateNewSite(!createNewSite)}
                  />
                  <label className="form-check-label" htmlFor="createNewSite">
                    My site does not exist in the list, I want to create a new
                    one.
                  </label>
                </div>
              )}

              {createNewSite && (
                <>
                  <div className="alert alert-warning" role="alert">
                    <span className="fw-bold">IMPORTANT:</span> Please first
                    search for an existing site and only create a new one if
                    you're certain that the site you're looking for doesn't
                    already exist.
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-medium">
                      New site name <span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={(event) =>
                        handleNewSiteChange(
                          event.target.name,
                          event.target.value
                        )
                      }
                    />
                    <div className="form-text">
                      Enter the site name in full (don't use abbreviations).
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-medium">
                      State <span className="required-asterisk">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="stateCode"
                      onChange={(event) =>
                        handleNewSiteChange(
                          event.target.name,
                          event.target.value
                        )
                      }
                    >
                      <option value=""></option>
                      {states.map((state, index) => (
                        <option key={index} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer">
              <a
                className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-center mx-3"
                href="#"
                data-bs-dismiss="modal"
                onClick={() => handleCancelClick()}
              >
                Cancel
              </a>
              {createNewSite && (
                <>
                  {formIsValid ? (
                    <div>
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        data-bs-dismiss="modal"
                        onClick={() => handleCreateNewSiteClick()}
                      >
                        Create new site
                      </button>
                    </div>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Please enter valid values for all required fields.
                        </Tooltip>
                      }
                    >
                      <div>
                        <button
                          className="btn btn-primary w-100"
                          type="button"
                          disabled={true}
                        >
                          Create new site
                        </button>
                      </div>
                    </OverlayTrigger>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
