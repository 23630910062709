import { apiClient } from "../services/api-client";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await apiClient.get("/refresh", { withCredentials: true });

    setAuth((prev: any) => {
      return {
        ...prev,
        accessToken: response.data.accessToken,
        user: response.data.user,
      };
    });

    return response.data.accessToken;
  };

  return refresh;
};

export default useRefreshToken;
