import React, { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import {
  isValidEmailAddress,
  isValidPhoneNumber,
  isValidPersonName,
  getPortalUrlPath,
  getPortalImagePath,
} from "../../services/utils";
import { apiClient } from "../../services/api-client";

import "./styles.scss";
import SiteSelect from "../../components/site-select";
import { useNavigate } from "react-router-dom";
import useAppContext from "../../contexts/app-context";
import StudyField from "../../components/study-field";
import LoadingSpinner from "../../components/loading-spinner";

export default function LogImpactPage() {
  const navigate = useNavigate();
  const { offlineMode, isLoading, impactStudy, impactInstance } =
    useAppContext();
  const { auth } = useAuth();

  // console.log("LogImpactPage init | ", impactStudy, impactInstance);

  // if (!impactStudy || !impactInstance) {
  //   navigate("/not-found");
  // }

  interface ImpactFormDataModel {
    user: UserModel;
    siteId: number;
    siteName: string;
    impactType: string;
    studyFields: Array<StudyFieldModel>;
  }

  const impactFormDefaults: ImpactFormDataModel = {
    user: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
    },
    siteId: 0,
    siteName: "",
    impactType: "",
    studyFields: [],
  };

  const impactTypeDefault = {
    id: 0,
    name: "",
    description: "",
  };

  const [formData, setFormData] = useState<ImpactFormDataModel>({
    ...impactFormDefaults,
  });
  const [validationResult, setValidationResult] = useState<ImpactFormDataModel>(
    {
      ...impactFormDefaults,
    }
  );
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [impactTypes, setImpactTypes] = useState<Array<ImpactTypeModel>>([]);
  // const [studyFields, setStudyFields] = useState<Array<StudyFieldModel>>([]);

  const getImpactTypes = async () => {
    if (offlineMode) {
      setImpactTypes(require("../../data/impact-types.json"));
    } else {
      await apiClient
        .get(`/impact-types`)
        .then((response: any) => {
          console.log("getImpactTypes response: ", response.data);

          const newResults = response.data
            .sort((a: any, b: any) => a.description > b.description)
            .map((item: any) => ({
              id: item.id,
              name: item.name,
              description: item.description,
            }));

          setImpactTypes(newResults);
        })
        .catch((err: any) => console.error(err));
    }
  };

  const getStudyFields = async () => {
    if (offlineMode) {
      const studyFields = require("../../data/impact-study-fields.json");

      setFormData((prevdata) => {
        return {
          ...prevdata,
          studyFields: studyFields,
        };
      });
    } else {
      await apiClient
        .get(`/impact-studies/${impactStudy.id}/fields`)
        .then((response: any) => {
          console.log("getStudyFields response: ", response.data);

          const newResults = response.data
            // .sort((a: any, b: any) => a.field_sequence > b.field_sequence)
            .map((item: any) => ({
              id: item.id,
              label: item.field_label,
              type: item.field_type,
              mandatory: item.field_is_mandatory,
            }));

          // setStudyFields(newResults);

          setFormData((prevdata) => {
            return {
              ...prevdata,
              studyFields: newResults,
            };
          });
        })
        .catch((err: any) => console.error(err));
    }
  };

  useEffect(() => {
    // update user details from auth

    if (auth?.user) {
      setFormData((prevdata) => {
        return {
          ...prevdata,
          user: {
            firstName: auth?.user?.firstName,
            lastName: auth?.user?.lastName,
            emailAddress: auth?.user?.emailAddress,
            mobileNumber: auth?.user?.mobileNumber,
          },
        };
      });
    }

    const sendRequests = async () => {
      await getImpactTypes();
    };

    sendRequests();
  }, []);

  useEffect(() => {
    const sendRequests = async () => {
      await getStudyFields();
    };

    if (impactStudy?.id) {
      sendRequests();
    }
  }, [impactStudy]);

  useEffect(() => {
    console.log("formData changed: ", formData);
    validateForm(formData);
  }, [formData]);

  const validateForm = (dataToValidate: ImpactFormDataModel) => {
    const newValidationResult: ImpactFormDataModel = {
      user: {
        firstName: isValidPersonName(dataToValidate.user.firstName)
          ? ""
          : "Invalid characters in name.",
        lastName: isValidPersonName(dataToValidate.user.lastName)
          ? ""
          : "Invalid characters in name.",
        emailAddress: isValidEmailAddress(dataToValidate.user.emailAddress)
          ? ""
          : "Invalid email address.",
        mobileNumber: isValidPhoneNumber(dataToValidate.user.mobileNumber)
          ? ""
          : "Invalid mobile number.",
      },
      siteId: 0,
      siteName:
        dataToValidate.siteId === 0 || !dataToValidate.siteName
          ? "Mandatory field."
          : "",
      impactType: "",
      studyFields: [],
    };

    setValidationResult(newValidationResult);

    const inputIsValid = !(
      newValidationResult.user.firstName ||
      newValidationResult.user.lastName ||
      newValidationResult.user.emailAddress ||
      newValidationResult.user.mobileNumber ||
      newValidationResult.siteName
    );

    setFormIsValid(inputIsValid);

    return inputIsValid;
  };

  const handleUserFieldChange = (fieldName: string, newValue: string) => {
    setErrorMessage("");

    setFormData((prevdata) => {
      return { ...prevdata, user: { ...prevdata.user, [fieldName]: newValue } };
    });
  };

  const handleStandardFieldChange = (fieldName: string, newValue: string) => {
    setErrorMessage("");

    setFormData((prevdata) => {
      return { ...prevdata, [fieldName]: newValue };
    });
  };

  const handleStudyFieldChange = (studyFieldId: number, newValue: string) => {
    setErrorMessage("");

    setFormData((prevdata) => {
      const newStudyFields = prevdata.studyFields.map((item, index) => {
        if (item.id === studyFieldId) {
          return { ...item, fieldValue: newValue };
        }

        return item;
      });

      return { ...prevdata, studyFields: newStudyFields };
    });
  };

  const updateSelectedSite = (siteId: string, siteName: string) => {
    handleStandardFieldChange("siteId", siteId);
    handleStandardFieldChange("siteName", siteName);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setErrorMessage("");

    try {
      if (validateForm(formData)) {
        const response = await apiClient.post("/impact", formData, {
          withCredentials: true,
        });

        setFormData({ ...impactFormDefaults });

        // TODO: Should the user be navigated to another page? It would also depend on whether they're logged in
        // navigate(from, { replace: true });
      }
    } catch (err: any) {
      console.error(err);

      if (!err?.response) {
        setErrorMessage("No server response. Please try again later.");
      } else if (err.response?.status === 400) {
        setErrorMessage("Missing email address or password.");
      } else {
        setErrorMessage("Failed to submit form. Please try again.");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-8">
                <div
                  className="card register-image-container"
                  style={{ width: "100%" }}
                >
                  <img
                    src={getPortalImagePath(impactInstance?.bannerFilePath)}
                    className="card-img-top"
                    alt="Banner image"
                  />
                  <div className="card-body text-start">
                    <h1 className="display-5 text-center">Log an impact</h1>
                    <form className="container" onSubmit={handleSubmit}>
                      {/* Form fields - Your details */}
                      <p className="fs-3">Your details</p>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-medium">
                            First name{" "}
                            <span className="required-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formData.user.firstName &&
                              (validationResult.user.firstName
                                ? "is-invalid"
                                : "is-valid")
                            }`}
                            name="firstName"
                            value={formData.user.firstName}
                            onChange={(event) =>
                              handleUserFieldChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            {validationResult.user.firstName}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-medium">
                            Last name
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formData.user.lastName &&
                              (validationResult.user.lastName
                                ? "is-invalid"
                                : "is-valid")
                            }`}
                            name="lastName"
                            value={formData.user.lastName}
                            onChange={(event) =>
                              handleUserFieldChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            {validationResult.user.lastName}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-medium">
                            Email address{" "}
                            <span className="required-asterisk">*</span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              formData.user.emailAddress &&
                              (validationResult.user.emailAddress
                                ? "is-invalid"
                                : "is-valid")
                            }`}
                            name="emailAddress"
                            value={formData.user.emailAddress}
                            onChange={(event) =>
                              handleUserFieldChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            {validationResult.user.emailAddress}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-medium">
                            Mobile number
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formData.user.mobileNumber &&
                              (validationResult.user.mobileNumber
                                ? "is-invalid"
                                : "is-valid")
                            }`}
                            name="mobileNumber"
                            value={formData.user.mobileNumber}
                            onChange={(event) =>
                              handleUserFieldChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            {validationResult.user.mobileNumber}
                          </div>
                        </div>
                      </div>

                      {/* Form fields - Impact details */}
                      <p className="fs-3">Impact details</p>
                      <p>
                        Reporting the numbers of health professionals you have
                        educated is key to helping us track progress toward
                        Nurses Climate Challenge Europe goal. Please fll out
                        this simple form to tell us about your event.
                      </p>
                      <p>
                        Information collected us used to track progress toward
                        our Australian-wide goal and to improve the supporting
                        materials. Specific information about you, your event,
                        and your hospital will not be shared publicly.
                      </p>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-medium">
                            Site name{" "}
                            <span className="required-asterisk">*</span>
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className={`form-control ${
                                formData.siteName &&
                                (validationResult.siteName
                                  ? "is-invalid"
                                  : "is-valid")
                              }`}
                              name="siteName"
                              value={formData.siteName}
                              onChange={(event) =>
                                handleStandardFieldChange(
                                  event.target.name,
                                  event.target.value
                                )
                              }
                              disabled
                            />

                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#site-selector-modal"
                            >
                              Choose
                            </button>
                          </div>
                          <div className="invalid-feedback">
                            {validationResult.siteName}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-medium">
                            Impact type
                          </label>
                          <select
                            className={`form-select ${
                              formData.impactType &&
                              (validationResult.impactType
                                ? "is-invalid"
                                : "is-valid")
                            }`}
                            name="impactType"
                            value={formData.impactType}
                            onChange={(event) =>
                              handleStandardFieldChange(
                                event.target.name,
                                event.target.value
                              )
                            }
                          >
                            <option value=""></option>
                            {impactTypes.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            {validationResult.impactType}
                          </div>
                        </div>
                      </div>

                      {/* Study-specific fields */}
                      <div className="row">
                        {formData.studyFields.map((field, index) => (
                          <StudyField
                            key={index}
                            field={field}
                            handleChange={handleStudyFieldChange}
                          />
                        ))}
                      </div>

                      {errorMessage && (
                        <div className="offset-2 col-8 mb-3 text-center">
                          <div className="alert alert-danger" role="alert">
                            {errorMessage}
                          </div>
                        </div>
                      )}

                      {/* Buttons & links */}
                      <div className="d-grid gap-2 col-6 mx-auto">
                        {formIsValid ? (
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                            data-bs-toggle="modal"
                            data-bs-target="#confirm-submission-modal"
                          >
                            Submit
                          </button>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Please enter valid values for all required
                                fields.
                              </Tooltip>
                            }
                          >
                            <div>
                              <button
                                className="btn btn-primary w-100"
                                type="button"
                                disabled={true}
                              >
                                Submit
                              </button>
                            </div>
                          </OverlayTrigger>
                        )}

                        <a
                          className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-center"
                          href={getPortalUrlPath("dashboard")}
                          role="button"
                        >
                          Cancel
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Site modal */}
          <SiteSelect updateSelectedSite={updateSelectedSite} />

          {/* Confirm submission modal */}
          <div
            className="modal fade"
            id="confirm-submission-modal"
            tabIndex={-1}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Impact logged</h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="alert alert-success" role="alert">
                    Your impact details have been successfully submitted.
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum sem nunc, sagittis vitae ipsum ultricies, laoreet
                    eleifend ex. Suspendisse risus tortor, scelerisque sed
                    sollicitudin a, volutpat ac ipsum. Etiam justo orci, finibus
                    in nisi porttitor, scelerisque feugiat justo. Fusce nec
                    suscipit massa.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
