import React from "react";
import { PulseLoader } from "react-spinners";

// https://www.davidhu.io/react-spinners/storybook/?path=/docs/pulseloader--main

// TODO: Implement
const LoadingSpinner = () => {
  return (
    <div className="container mt-5">
      <br />
      <br />
      <br />
      <br />
      <div className="row justify-content-center">
        <div className="col-6 text-center">
          <PulseLoader color="#36d7b7" size={20} />
          <p className="fs-3 mt-4">Loading impact study—please be patient...</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
