import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";

interface StudyFieldProps {
  field: StudyFieldModel;
  handleChange(studyFieldId: number, newValue: string): void;
}

const StudyField = ({ field, handleChange }: StudyFieldProps) => {
  console.log("StudyField init | ", field);

  // const inputRef = useRef(null);
  const [startDate, setStartDate] = useState(new Date());

  let colClassName = field.type === "textarea" ? "col-md-12" : "col-md-6";

  return (
    <div className={`${colClassName} mb-3`}>
      <label className="form-label fw-medium">
        {field.label}{" "}
        {field.mandatory ? <span className="required-asterisk">*</span> : <></>}
      </label>

      {(field.type === "text" || field.type === "number") && (
        <input
          type={field.type}
          className={"form-control"}
          // className={`form-control ${
          //   formData.activityName &&
          //   (validationResult.activityName ? "is-invalid" : "is-valid")
          // }`}
          value={field.value}
          onChange={(event: any) => handleChange(field.id, event.target.value)}
        />
      )}

      {field.type === "date" && (
        // <input
        //   type="text"
        //   className={"form-control"}
        //   // className={`form-control ${
        //   //   formData.activityName &&
        //   //   (validationResult.activityName ? "is-invalid" : "is-valid")
        //   // }`}
        //   value={field.value}
        //   onChange={(event: any) => handleChange(field.id, event.target.value)}
        // />
        <div className="input-group mb-3 w-100">
          <DatePicker
            // ref={inputRef}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control"
          />
          <span
            className="input-group-text"
            // onClick={() => {
            //   inputRef.current.focus();
            // }}
          >
            <i className="bi bi-calendar-week"></i>
          </span>
        </div>
      )}

      {field.type === "textarea" && (
        <textarea
          className={"form-control"}
          //   className={`form-control ${
          //     formData.feedback &&
          //     (validationResult.feedback ? "is-invalid" : "is-valid")
          //   }`}
          value={field.value}
          onChange={(event: any) => handleChange(field.id, event.target.value)}
        ></textarea>
      )}

      <div className="invalid-feedback">{field.error}</div>
    </div>
  );
};

export default StudyField;
