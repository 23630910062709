import React from "react";
import { getPortalUrlPath } from "../../services/utils";

import "./styles.scss";

export default function NotFoundPage() {
  return (
    <>
      <div className="container mt-5 mb-5 text-center">
        <div className="row text-center align-items-center mb-3">
          <div className="offset-2 col-8">
            <h1 className="display-3">Well, this is embarrassing.</h1>
            <br />
            <br />
            <p>The URL you've entered does not exist.</p>
            <br />
            <a
              className="btn btn-primary"
              href={getPortalUrlPath("login")}
              role="button"
            >
              <i className="bi bi-box-arrow-in-right"></i> Go to login page
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
