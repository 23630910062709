import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "https://appapi.startupstatus.co",
  headers: { "Content-Type": "application/json" },
  accesscontrolalloworigin: "*",
  accesscontrolallowMethods: "GET, POST, PUT, DELETE, PATCH, OPTIONS",
});

export const apiClientPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "https://appapi.startupstatus.co",
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  accesscontrolalloworigin: "*",
  accesscontrolallowMethods: "GET, POST, PUT, DELETE, PATCH, OPTIONS",
});
