export function getPortalUrlPath(path) {
  return `/${path}`;
}

export function getPortalImagePath(path) {
  // if (process.env.REACT_APP_ENVIRONMENT === "development") {
  //   return `/${path}`;
  // }

  return `${path}`;
}

export function generateRandomString(
  length,
  includeAlpha,
  includeNumeric,
  includeSpecial
) {
  const alpha_set = "abcdefghijlkmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numeric_set = "0123456789";
  const special_set = "!@#$%^&*()-_=+{}[]:;'<>,./?";

  let char_set = "";

  if (includeAlpha) {
    char_set += alpha_set;
  }

  if (includeNumeric) {
    char_set += numeric_set;
  }

  if (includeSpecial) {
    char_set += special_set;
  }

  let result = "";

  for (let i = 0; i < length; i++) {
    result += char_set[Math.floor(Math.random() * (char_set.length - 1))];
  }

  return result;
}

export function isAlphanumeric(value) {
  return value.match(/^[a-zA-Z0-9]+$/) !== null;
}

export function isValidEmailAddress(emailAddress) {
  if (!emailAddress) {
    return false;
  }

  try {
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(emailAddress);
  } catch (err) {
    console.error("Error testing email address:", err);
    return false;
  }
}

export function isValidPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }

  try {
    var regex =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return regex.test(phoneNumber);
  } catch (err) {
    console.error("Error testing phone number:", err);
    return false;
  }
}

export function isValidPassword(password) {
  if (!password) {
    return false;
  }

  try {
    var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
    return regex.test(password);
  } catch (err) {
    console.error("Error testing password:", err);
    return false;
  }
}

// TODO: We need to limit the characters without restricting valid names which use non-latin character sets
export function isValidPersonName(name) {
  if (!name) {
    return false;
  }

  try {
    var regex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    return regex.test(name);
  } catch (err) {
    console.error("Error testing name:", err);
    return false;
  }
}
