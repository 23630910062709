import React, { useContext, useState, useEffect } from "react";
import { apiClient } from "../services/api-client";
import { useSearchParams } from "react-router-dom";

interface AppContextModel {
  offlineMode: boolean;
  isLoading: boolean;
  impactStudy: ImpactStudyModel;
  impactInstance: ImpactInstanceModel;
}

const AppContext = React.createContext<AppContextModel>(null);

export const AppProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(); // TODO: Display the error to the user
  const [impactStudy, setImpactStudy] = useState<ImpactStudyModel>();
  const [impactInstance, setImpactInstance] = useState<ImpactInstanceModel>();

  const [searchParams, setSearchParams] = useSearchParams();
  // const org = searchParams.get("org");
  const studyId = searchParams.get("study");

  // When offline = true, data will be read from json files instead of making API calls
  const offlineMode = true;

  // console.log("AppProvider init | ", searchParams, studyId);

  const getInstance = async (instanceId: number) => {
    console.log("getInstance, ", offlineMode);

    if (offlineMode) {
      const instances = require("../data/impact-instances.json");
      setImpactInstance(
        instances.find(
          (instance: ImpactInstanceModel) => instance.id === instanceId
        )
      );
    } else {
      await apiClient
        .get(`/impact-instances/${instanceId}`)
        .then((response: any) => {
          // console.log("getInstance | response: ", response);

          setImpactInstance({
            id: response.data.id,
            description: response.data.description,
            backgroundColourHex: response.data.background_colour_hex,
            bannerFilePath: response.data.banner_file_path,
          });
        })
        .catch((err: any) => console.error(err));
    }
  };

  const getStudy = async () => {
    console.log("getStudy, ", offlineMode);

    if (offlineMode) {
      const studies = require("../data/impact-studies.json");
      setImpactStudy(
        studies.find(
          (study: ImpactStudyModel) => study.id.toString() === studyId
        )
      );
    } else {
      await apiClient
        .get(`/impact-studies/${studyId}`)
        .then((response: any) => {
          // console.log("getStudy | response: ", response);

          setImpactStudy({
            id: response.data.id,
            description: response.data.description,
            impactInstanceId: response.data.impact_instance_id,
            impactTypeId: response.data.impact_type_id,
            studyStart: response.data.study_start,
            studyEnd: response.data.study_end,
          });
        })
        .catch((err: any) => console.error(err));
    }
  };

  useEffect(() => {
    console.log("useEffect | []", offlineMode, studyId);

    if (studyId) {
      getStudy();
    }
  }, []);

  useEffect(() => {
    console.log("useEffect | [impactStudy]", offlineMode, impactStudy);

    if (impactStudy?.impactInstanceId) {
      getInstance(impactStudy.impactInstanceId);
    }
  }, [impactStudy]);

  useEffect(() => {
    console.log(
      "useEffect | [impactStudy, impactInstance]",
      offlineMode,
      impactStudy,
      impactInstance
    );

    if (impactStudy && impactInstance) {
      setIsLoading(false);
    }
  }, [impactStudy, impactInstance]);

  return (
    <AppContext.Provider
      value={{ offlineMode, isLoading, impactStudy, impactInstance }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within the AppProvider.");
  }
  return context;
};

export default useAppContext;
