import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import useAppContext from "../../contexts/app-context";
import NavBar from "../navbar";
import Footer from "../footer";

import "./styles.scss";

const Layout = () => {
  const { impactInstance } = useAppContext();

  useEffect(() => {
    // set the theme background scss variable
    document.documentElement.style.setProperty(
      "--theme-background-color",
      impactInstance?.backgroundColourHex
    );
  }, [impactInstance]);

  return (
    <main className="App">
      {/* TODO: Re-enable this once the details of the navbar have been confirmed */}
      {/* <NavBar /> */}

      <Outlet />

      {/* TODO: Re-enable this once the details of the footer have been confirmed */}
      {/* <Footer /> */}
    </main>
  );
};

export default Layout;
